import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { appleStore, googleStore } from "../images";

const Payments = ({ showPayment, setShowPayment, setActiveMenu }) => {
  const { t } = useTranslation();
  const [activeHeader, setActiveHeader] = useState("FHF-easy-pay");
  return (
    <>
      <div className="payments">
        <div className="payments__top-section">
          <h1>{t("payments.header")}</h1>
          <p>{t("payments.tagline")}</p>
        </div>
        <div className="payments__middle-section">
          <div className="payments__middle-section__left">
            {/* FHF Easy Pay */}
            <div
              className={`payments__middle-section__left__contents ${
                activeHeader === "FHF-easy-pay" ? "active-header" : ""
              }`}
            >
              <h4
                onClick={() => {
                  setActiveHeader("FHF-easy-pay");
                }}
              >
                {t("payments.FHF Easy Pay.self")}
              </h4>

              <p>
                {" "}
                <Trans
                  i18nKey="payments.FHF Easy Pay.content.left"
                  components={{
                    b: <strong className="bold-blue"></strong>,
                  }}
                ></Trans>
              </p>
            </div>
            {/* Check or Money by Mail */}
            <div
              className={`payments__middle-section__left__contents ${
                activeHeader === "Money-by-mail" ? "active-header" : ""
              }`}
            >
              <h4
                onClick={() => {
                  setActiveHeader("Money-by-mail");
                }}
              >
                {t("payments.Check or Money by Mail.self")}
              </h4>
              <p>{t("payments.Check or Money by Mail.content.left")}</p>
            </div>
            {/*  Debit Card or Bank Account Via Our App */}
            <div
              className={`payments__middle-section__left__contents ${
                activeHeader === "Debit-Bank" ? "active-header" : ""
              }`}
            >
              <h4
                onClick={() => {
                  setActiveHeader("Debit-Bank");
                }}
              >
                {t("payments.Debit Card or Bank Account Via Our App.self")}
              </h4>
              <p>
                {t(
                  "payments.Debit Card or Bank Account Via Our App.content.left"
                )}
              </p>
            </div>
            {/* Pay Cash With MoneyGram */}
            <div
              className={`payments__middle-section__left__contents ${
                activeHeader === "MoneyGram" ? "active-header" : ""
              }`}
            >
              <h4
                onClick={() => {
                  setActiveHeader("MoneyGram");
                }}
              >
                {t("payments.Pay Cash With MoneyGram.self")}
              </h4>
              <p>{t("payments.Pay Cash With MoneyGram.content.left")}</p>
            </div>
            {/* Check or Money Order by Phone */}
            <div
              className={`payments__middle-section__left__contents ${
                activeHeader === "Order-by-phone" ? "active-header" : ""
              }`}
            >
              <h4
                onClick={() => {
                  setActiveHeader("Order-by-phone");
                }}
              >
                {t("payments.Check or Money Order by Phone.self")}
              </h4>
              <p>{t("payments.Check or Money Order by Phone.content.left")}</p>
            </div>
            {/* Pay Cash at 7-Eleven and CVS Stores */}
            <div
              className={`payments__middle-section__left__contents ${
                activeHeader === "7-Eleven" ? "active-header" : ""
              }`}
            >
              <h4
                onClick={() => {
                  setActiveHeader("7-Eleven");
                }}
              >
                {t("payments.Pay Cash at 7-Eleven and CVS Stores.self")}
              </h4>
              <p>
                {t("payments.Pay Cash at 7-Eleven and CVS Stores.content.left")}
              </p>
            </div>
          </div>
          <div className="line"></div>
          {/* right section */}

          <div className="payments__middle-section__right">
            {/* FHF easy pay */}
            {activeHeader === "FHF-easy-pay" ? (
              <div className="payments__middle-section__right__contents">
                <h4>{t("payments.FHF Easy Pay.self")}</h4>
                <p>{t("payments.FHF Easy Pay.content.right")}</p>
              </div>
            ) : (
              ""
            )}
            {/*  Check or Money by Mail*/}
            {activeHeader === "Money-by-mail" ? (
              <div className="payments__middle-section__right__contents">
                <h4>{t("payments.Check or Money by Mail.self")}</h4>
                <p>
                  <Trans
                    i18nKey="payments.Check or Money by Mail.content.right"
                    components={{
                      b: <p style={{ fontWeight: 500 }}></p>,
                      p: <p className="alert"></p>,
                    }}
                  ></Trans>
                </p>
                <div className="addresses">
                  <div>
                    <h3>USPS Address:</h3>
                    <p className="address-text">
                      First Help Financial, P.O. Box 95517, Chicago, IL
                      60694-5517
                    </p>

                    <p>
                      <em>
                        * NOTE: The above address must not be used for packages
                        sent via courier (FedEx/UPS). For this, please use the
                        following address:
                      </em>
                    </p>
                  </div>
                  <div>
                    <h3>Fed-Ex/UPS Address: </h3>
                    <p className="address-text">
                      First Help Financial, Conduent c/o BMO Harris, LBX 95517,
                      141 W Jackson Blvd/Suite 1000, Chicago, IL 60604
                    </p>

                    <p>
                      <em>
                        Please be sure to mail your payment at least 5 days in
                        advance of your due date so it arrives by your due date.
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* Debit Card or Bank Account Via Our App  */}
            {activeHeader === "Debit-Bank" ? (
              <div className="payments__middle-section__right__contents">
                <h4>
                  {t("payments.Debit Card or Bank Account Via Our App.self")}
                </h4>
                <p>
                  {t(
                    "payments.Debit Card or Bank Account Via Our App.content.right"
                  )}
                </p>
                <div className="downlaod-buttons">
                  <a
                    href="https://itunes.apple.com/us/app/first-help-financial/id1348601768"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>
                      <img src={appleStore} alt="apple store"></img>
                    </button>
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.appster.payix.firsthelp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button>
                      <img src={googleStore} alt="google store"></img>
                    </button>
                  </a>
                </div>
                <div className="pay-now-button">
                  <button
                    className="hover-button"
                    onClick={() => {
                      window.open(
                        "https://www.myfirsthelploan.com/login",
                        "_blank"
                      );
                    }}
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* Pay Cash With MoneyGram */}
            {activeHeader === "MoneyGram" ? (
              <div className="payments__middle-section__right__contents">
                <h4>{t("payments.Pay Cash With MoneyGram.self")}</h4>
                <p>
                  <Trans
                    i18nKey="payments.Pay Cash With MoneyGram.content.right"
                    components={{
                      a: (
                        <a
                          href="https://www.moneygram.com/mgo/us/en/locations"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {""}
                        </a>
                      ),
                    }}
                  ></Trans>
                </p>
              </div>
            ) : (
              ""
            )}
            {/* Check or Money Order by Phone */}
            {activeHeader === "Order-by-phone" ? (
              <div className="payments__middle-section__right__contents">
                <h4>{t("payments.Check or Money Order by Phone.self")}</h4>
                <p>
                  <Trans
                    i18nKey="payments.Check or Money Order by Phone.content.right"
                    components={{
                      a: <a href="tel:(866) 343 4345">{""}</a>,
                    }}
                  ></Trans>
                </p>
              </div>
            ) : (
              ""
            )}
            {/*Pay Cash at 7-Eleven and CVS Stores */}
            {activeHeader === "7-Eleven" ? (
              <div className="payments__middle-section__right__contents">
                <h4>
                  {t("payments.Pay Cash at 7-Eleven and CVS Stores.self")}
                </h4>
                <p>
                  {t(
                    "payments.Pay Cash at 7-Eleven and CVS Stores.content.right"
                  )}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="payments__bottom-section">
          <button
            className="button-close hover-button"
            onClick={() => {
              setShowPayment(false);
              setActiveMenu(window.location.pathname);
            }}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default Payments;
